import { useHomeStore } from 'pages/Home/store'
import { useMemo } from 'react'

const useAuthHeaders = () => {
  const { accessToken } = useHomeStore()

  const headers = useMemo(
    () => ({
      accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    }),
    [accessToken]
  )

  return headers
}

export default useAuthHeaders
