const useAdminHeaders = () => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: 'Bearer Ns88SBO2LFxLHD6FWmPVO4manmNbve1RY',
  }

  return headers
}

export default useAdminHeaders
