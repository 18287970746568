import React, { useState } from 'react'
import { Form, FormControl, Container, Row, Col } from 'react-bootstrap'
import { UilSearch } from '@iconscout/react-unicons'
import StyleService from '../../services/StyleService'
import logo from '../../common/assets/logs/logo.png' // Import the logo image

const Search4 = (props) => {
  const sectionStyle = StyleService.getStylesFromProps(props, 'Section')
  const searchData = props
  const showLogo = searchData.showLogo

  // State to track if the search icon is clicked
  const [isSearchClicked, setIsSearchClicked] = useState(false)

  // Function to handle click on the search icon
  const handleSearchIconClick = () => {
    setIsSearchClicked(true)
  }

  // Function to handle blur event on the search icon
  const handleSearchIconBlur = () => {
    setIsSearchClicked(false)
  }

  return (
    <div className="header">
      <Container>
        <Row style={{ width: '100%' }}>
          {showLogo && (
            <Col xs={1} className="icon-container">
              <img
                style={{ width: '24px', height: '24px', objectFit: 'contain' }}
                src={logo}
              />
            </Col>
          )}

          {/* Show FormControl only when search icon is clicked */}
          {isSearchClicked && (
            <Col xs={11} md={11}>
              <Form>
                <FormControl
                  type="text"
                  placeholder={searchData.searchText}
                  className="flex-grow custom-form-control"
                />
              </Form>
            </Col>
          )}

          {/* Search Icon */}
          {!isSearchClicked && (
            <Col
              xs={11}
              className="icon-container"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <UilSearch
                size={20}
                onClick={handleSearchIconClick}
                onBlur={handleSearchIconBlur}
                style={{ cursor: 'pointer' }}
              />
              <div>{searchData.searchText}</div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default Search4
