const icons = {
  googleIconWhite: require('./files/google-icon-white.svg').default,
  sendIcon: require('./files/send-icon.svg').default,
  tickBlackIcon: require('./files/tick-black.svg').default,
  tickGreenIcon: require('./files/tick-green.svg').default,
  arrowUpIcon: require('./files/arrow-up.svg').default,
  arrowDownIcon: require('./files/arrow-down.svg').default,
  linkedInIcon: require('./files/linkedin.svg').default,
  twitterIcon: require('./files/twitter-x.svg').default,
  googleIconGreen: require('./files/google-icon-green.svg').default,
  interacxionLogo: require('./files/interacxion-logo.svg').default,
}

export default icons
