import React from 'react'
import styles from './Banner1.module.scss'
import Carousel from 'react-bootstrap/Carousel'
import { Image } from 'common/UI/Image'

const ScrollableBanner = ({ cardContentArray }) => {
  return (
    <Carousel className={styles.carousel}>
      {cardContentArray?.map((cardContent) => (
        <Carousel.Item className={styles.carouselItem} key={cardContent?.image}>
          <Image
            className={styles.image}
            src={cardContent?.images?.[0] || cardContent?.images}
          />
          <Carousel.Caption>
            <div className={styles.contentWrapper}>
              <div className={styles.title}>{cardContent?.title}</div>
              <div className={styles.subtitle}>{cardContent?.subText}</div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default ScrollableBanner
