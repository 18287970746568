const ENV = process.env.REACT_APP_HOST_ENV || 'development'

const development = {
  ENV: ENV,
  BASE_URL: 'https://74hbc9zp5b.execute-api.ap-south-1.amazonaws.com',
  GOOGLE_CLIENT_ID:
    '483377632163-cllqo0o2fmdqeo621r8e8qsdas24cv66.apps.googleusercontent.com',
  HOTJAR_ID: 3885299,
  HOTJAR_SNIPPET_VERSION: 6
}

const production = {
  ...development,
  BASE_URL: 'https://74hbc9zp5b.execute-api.ap-south-1.amazonaws.com',
  GOOGLE_CLIENT_ID:
    '483377632163-cllqo0o2fmdqeo621r8e8qsdas24cv66.apps.googleusercontent.com',
}

const configs = {
  development,
  production,
}

const currentConfig = configs[ENV]

const config = {
  ENV: ENV,
  BASE_URL: currentConfig.BASE_URL,
  GOOGLE_CLIENT_ID: currentConfig.GOOGLE_CLIENT_ID,
  HOTJAR_ID: currentConfig.HOTJAR_ID,
  HOTJAR_SNIPPET_VERSION: currentConfig.HOTJAR_SNIPPET_VERSION,
}

export default config
