import { Navbar, Container, Col, Nav, Row } from 'react-bootstrap'
import { UilComment, UilBell } from '@iconscout/react-unicons'
import React from 'react'
import PropTypes from 'prop-types'

import styles from './Header2.module.scss'

const Header2 = ({ props }) => {
  const {
    companyLogo,
    companyName = 'Google',
    showComment = true,
    showNotification = true,
    showSearch,
    showProfile,
    showBackgroundImage,
  } = props

  return (
    <div className={styles.headerWrapper}>
      <Container style={{ padding: '0px' }}>
        <Navbar className="nav-bar" style={{ padding: '0px' }}>
          <Row style={{ width: '100%', margin: '0px' }}>
            <Col xs={9} style={{ padding: '0px' }}>
              <Navbar.Brand href="#home">
                {companyLogo && (
                  <img
                    className={styles.iconImageContainer}
                    src={companyLogo}
                    alt={companyLogo}
                  />
                )}
                {companyName && (
                  <span className={styles.companyName}>{companyName}</span>
                )}
              </Navbar.Brand>
            </Col>
            <Col
              xs={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 'var(--Sp6)',
              }}
            >
              {showComment && (
                <div>
                  <Nav.Link>
                    <UilComment size={20} />
                  </Nav.Link>
                </div>
              )}
              {showNotification && (
                <div>
                  <Nav.Link>
                    <UilBell size={20} />
                  </Nav.Link>
                </div>
              )}
              {/* </Nav> */}
            </Col>
          </Row>
        </Navbar>
      </Container>
    </div>
  )
}

Header2.propTypes = {
  props: PropTypes.object,
}

export default Header2
