import classNames from 'classnames'
import { FlexView } from '../FlexView'
import styles from './IconButton.module.scss'
import { Loader } from '../Loader'

const IconButton = ({
  variant = 'secondary',
  className,
  loading,
  selected,
  onClick,
  children,
  gutter = 9,
  gap = 5,
  disabled,
}) => {
  const iconButtonClass = classNames(styles.iconButtonWrapper, {
    [className || '']: true,
    [styles[variant]]: variant,
    [styles.selected]: selected,
    [styles.disabled]: disabled,
    [styles.loading]: loading,
  })

  return (
    <FlexView
      className={iconButtonClass}
      borderRadius={8}
      gutter={gutter}
      gap={gap}
      inherit
      alignItems="center"
      onClick={onClick}
      justifyContent="center"
    >
      {loading ? <Loader /> : children}
    </FlexView>
  )
}

export default IconButton
