import classNames from 'classnames'
import { FlexView } from '../FlexView'
import styles from './Input.module.scss'

const Input = ({ className, id, onChange, children, ...rest }) => {
  const inputClass = classNames(styles.input, {
    [className || '']: true,
  })
  return (
    <FlexView className={styles.inputWrapper}>
      <textarea className={inputClass} onChange={onChange} {...rest} />
      {children ?? null}
    </FlexView>
  )
}

export default Input
