import { useState, useEffect } from 'react';

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState('');

  const checkDeviceType = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 360) {
      setDeviceType('small-mobile');
    } else if (screenWidth <= 768) {
      setDeviceType('mobile');
    } else if (screenWidth <= 992) {
      setDeviceType('tablet');
    } else {
      setDeviceType('desktop');
    }
  };

  useEffect(() => {
    // Check device type on component mount
    checkDeviceType();

    // Attach a resize event listener to update device type on window resize
    const handleResize = () => {
      checkDeviceType();
    };

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only on mount and unmount

  const isMobile = deviceType !== 'desktop'
  const isTablet = deviceType === 'tablet'
  
  return {
    deviceType,
    isMobile,
    isTablet
  };
};

export default useDeviceType;
