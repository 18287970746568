import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useActivePrompt } from 'common/hooks/useActivePrompt'
import useAuthHeaders from 'common/hooks/useAuthHeaders'
import { usePromptStore } from 'pages/Prompt/store'

const useLogic = () => {
  const {
    isLoading,
    uiGeneratorData,
    processData,
    uiGeneratorLoading,
    getProcessDetails,
    styleConfig,
  } = usePromptStore()
  const headers = useAuthHeaders()
  const { id } = useParams()
  const { prompt, createdAt } = useActivePrompt(id)
  useEffect(() => {
    id && getProcessDetails({ processId: id, headers })
  }, [id, headers, getProcessDetails])

  const showLoader =
    isLoading ||
    (id && processData && processData.status !== 'completed') ||
    uiGeneratorLoading

  return {
    prompt,
    uiGeneratorData,
    showLoader,
    createdAt,
    styleConfig,
  }
}

export default useLogic
