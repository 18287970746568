import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config.js'; // Import the configuration file
import inputcomponents from '../inputcomponents.json';
import Components from '../createcomponents.js';

function AllComponents({ postData }) {
  const [componentData, setComponentData] = useState([]);
  const [processID, setProcessId] = useState('')
  const [processStatus, setProcessStatus] = useState({})
  const [processDetails, setProcessDetails] = useState({})
  const [statusByProcessId, setStatusByProcessId] = useState('')
  const [UIdata , setUIData] = useState('')
  const [UIArray, setUIArray] = useState([])
  const [updatedUIArray, setUpdatedUIArray] = useState([])
  const [imageUrl, setImageUrl] = useState([]);

  useEffect(() => {
      async function fetchID(){
        let data = {"prompt": "coconut cosmetic product selling company"}
        const responseId = await getProcessID(data)
        console.log('responseId', responseId)
        setProcessStatus(responseId.data)
        // const uiDataResponse = await getUIData(responseId?.data?.process_id)
        const uiDataResponse = await getUIData('8f146582-5d66-4302-b03e-d841514ba843')
        setProcessDetails(uiDataResponse?.data?.data)
        setStatusByProcessId(uiDataResponse?.data?.data?.status)
        let dataForUI = uiDataResponse?.data?.data?.Output ? JSON.parse(uiDataResponse?.data?.data?.Output) : ""
        setUIData(dataForUI)
      }
      fetchID()
  },[])

  useEffect(() => {

    //Uses s3bucket to display images
    const AWS = require('aws-sdk');
      const s3 = new AWS.S3({
        accessKeyId: 'AKIA2EJE2JZODYQ3NA5R',
        secretAccessKey: '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',
        signatureVersion: 'v4',
        region: 'ap-south-1'
      });

    //CONVERTER: API data converts to data that can be used to display prebuilt components
    const UIResposeConverter = (respObj) => {
      let obj = respObj.output

      let SanitizedObject = {}
      for (const key in obj){
        if (obj.hasOwnProperty(key)) {
          if (typeof (obj[key]) === 'object' && !Array.isArray(obj[key])) {
            if(obj[key].hasOwnProperty('component')){
              SanitizedObject[key] = obj[key]
            }
          }
        }
      }

      var arr = []
      for (const key in SanitizedObject) {//First Level Object Intrusion
        if (obj.hasOwnProperty(key)) {
          if (typeof (obj[key]) === 'object' && !Array.isArray(obj[key])) {
            var Obj = {}
            for (const innerKey in obj[key]) {// second level object intrusion
              if (innerKey === 'component' && typeof (obj[key][innerKey]) === 'string') {
                Obj['component'] = obj[key][innerKey]
              } else if (innerKey === 'props' && typeof (obj[key][innerKey]) === 'object') {
                Obj['props'] = obj[key][innerKey]
              } else if (Obj.hasOwnProperty('props')) {
                Obj['props'][innerKey] = obj[key][innerKey]
                if (Obj.hasOwnProperty('props')) {
                  Obj['props']['sectionContent'] = obj[key][innerKey]
                } else {
                  Obj['props'] = {}
                  Obj['props']['sectionContent'] = obj[key][innerKey]
                }
              } 
            }
            arr.push(Obj)
          }
        }
      }   

      setUIArray(arr)
    }

    UIResposeConverter(UIdata)
  }, [UIdata])

  useEffect(() => {
    // Fetch the image URL from your S3 bucket
    // You can use the AWS SDK to interact with your S3 bucket
    // Replace <YOUR_ACCESS_KEY_ID>, <YOUR_SECRET_ACCESS_KEY>, and <YOUR_BUCKET_NAME> with your actual values
    const AWS = require('aws-sdk');
    const s3 = new AWS.S3({
      accessKeyId: 'AKIA2EJE2JZODYQ3NA5R',
      secretAccessKey: 'o3xFYGuNZt8cz277HiRFuAT1Aqo6R2i4V2MZQXm4',
      signatureVersion: 'v4',
      region: 'ap-south-1'
    });

    let ArrayOfImages = []

    UIArray.forEach((item) => {
      if(item?.props?.companyLogo){

        ArrayOfImages.push(item?.props?.companyLogo)
      }

      if(Array.isArray(item?.props?.cardsContent)){
        (item?.props?.cardsContent).forEach((value) => {
          ArrayOfImages.push(value.image)
        })
      }
    })

    

    var imageCollection = []

    ArrayOfImages.forEach(async (item) => {
      let imageName = item.substring(item.lastIndexOf('/') + 1)
      const params = {
        Bucket: 'ai-generated-ui-contracts',
        Key: `26342f1f-c9cc-49b8-8e90-6a9def130a47/${imageName}`,
      };

      await s3.getSignedUrl('getObject', params, (err, url) => {
        if (err) {
          console.error('Error getting S3 image URL:', err);
        } else {
          imageCollection.push(url)
          setImageUrl(imageCollection);
          
        }
      });
    })

    //Replace with async await
    setTimeout(() => {
    let UpdatedUIArray = UIArray.map((item) => {
      if(item?.props?.companyLogo){
        const logo = imageCollection[0]
        const newProps = {...item.props, companyLogo : logo}
        
        let newItem = {
          ...item,
          props : newProps
        }
        return newItem
      }

      if(Array.isArray(item?.props?.cardsContent)){
        let i = 1
        let _array = (item?.props?.cardsContent).map((value) => {
          let _val = {...value, image: imageCollection[i] }
          i++
          return _val
          
        })
        const _newProps = {...item.props, cardsContent: _array}
        let _newItem = {
          ...item,
          props : _newProps
        }
        return _newItem
      }

      return item
    })

  setUpdatedUIArray(UpdatedUIArray)
  }, 100);
    
  }, [UIArray.join()]);

  const inputPayload={
    "prompt": "coconut cosmetic product selling company"
  }

  const axiosConfig  = { headers : {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer interaxcion-58082c697f7306d6d2f3b038bcbbff369623e6762579c7ac92dc9e59cedfe1bf-5e39d936d3758b7f549c19d54aa6c1d1',
    }
  }

  const getProcessID = async(promptData) => {
    return await axios.post(`${config.apiEndpoints.processService}`, promptData , axiosConfig  )
  }

  const getList = async(a) => {
    return await axios.get(`https://74hbc9zp5b.execute-api.ap-south-1.amazonaws.com/user-processes?status=completed`, axiosConfig  )
  }

  const getUIData = async(_processId) => {
    return await axios.get(`${config.apiEndpoints.statusService}/${_processId}`, axiosConfig )
  }

  return (
    <>  
        {statusByProcessId !== 'completed' ? <p style={{color:'white', fontSize: '24px', textTransform:'uppercase'}}>{ statusByProcessId }</p> : (
        <>
                  {updatedUIArray.length == 0 && <div>Loading...</div>}      
                  {inputcomponents.length > 0 && inputcomponents.map((block) => {
                    return <div className="app">
                      <div style={{background: 'white'}}>{`name : ${block.component}`}</div>
                      <div className="background">
                    <div className="mobile-container">
                      <div style={{"margin":"25px"}}>
                      </div> 
                      {Components(block)}
                      <div style={{"margin":"25px"}}>
                      </div> 
                      </div>
                    </div>
                    </div>
                  })}
                              
                  
          </> )    
        } 
    </>
  );
}

export default AllComponents;
