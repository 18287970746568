const AWS = require('aws-sdk')

const processDetailsConverter = async (processData = {}) => {
  window.console.log('processData', processData)
  const s3 = new AWS.S3({
    accessKeyId: 'AKIA2EJE2JZOPJMEO5GH',
    secretAccessKey: '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',
    signatureVersion: 'v4',
    region: 'ap-south-1',
  })

  let obj = processData.output
  let SanitizedObject = {}
  let styleConfig = {}

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        if (obj[key].hasOwnProperty('component')) {
          SanitizedObject[key] = obj[key]
        } else {
          if (
            ['global_styles', 'suggested_font', 'color_info']?.includes(key)
          ) {
            styleConfig[key] = obj[key]
          }
        }
      }
    }
  }

  const arr = Object.keys(SanitizedObject).map((key) => {
    const Obj = {}
    for (const innerKey in obj[key]) {
      if (innerKey === 'component' && typeof obj[key][innerKey] === 'string') {
        Obj['component'] = obj[key][innerKey]
      } else if (
        innerKey === 'props' &&
        typeof obj[key][innerKey] === 'object'
      ) {
        Obj['props'] = obj[key][innerKey]
      } else if (Obj.hasOwnProperty('props')) {
        Obj['props'][innerKey] = obj[key][innerKey]
        if (Obj.hasOwnProperty('props')) {
          Obj['props']['sectionContent'] = obj[key][innerKey]
        } else {
          Obj['props'] = {}
          Obj['props']['sectionContent'] = obj[key][innerKey]
        }
      }
    }
    return Obj
  })

  const convertedData = arr.map((item) => {
    if (item.component === 'Banner1') {
      const bannerObj = {
        component: 'banner1',
        props: {
          ...item.props,
          cardContent: {
            ...item.props.cardContent,
            subtitle: item.props.cardContent.subText,
            images: item.props.cardContent[0].images[0],
          },
        },
      }
      return bannerObj
    }
    return item
  })

  // All the images in all the generated components
  // const ArrayOfImages = []
  // updatedArr?.forEach((item) => {
  //   if (item?.props?.companyLogo) {
  //     ArrayOfImages.push(item?.props?.companyLogo)
  //   }

  //   //Temporary fix until backend issue for banner1 is fixed
  //   const bannerOneImages =
  //     item.component === 'banner1' &&
  //     Object.keys(item?.props?.cardContent)?.length !== 1

  //   if (bannerOneImages) {
  //     const cardContentArray = Object.values(item?.props?.cardContent)
  //       ?.filter((item) => typeof item === 'object')
  //       ?.map((item) => item)

  //     cardContentArray.forEach((value) => {
  //       ArrayOfImages.push(value?.images[0] || value?.images)
  //     })
  //   }

  //   if (item.component === 'Banner3') {
  //     const _cardContent = item?.props?.cardContent?.[0]
  //     _cardContent?.images?.forEach((item) => {
  //       ArrayOfImages.push(item)
  //     })
  //   }
  //   //Temporary  fix end

  //   if (Array.isArray(item?.props?.cardsContent)) {
  //     item?.props?.cardsContent.forEach((value) => {
  //       ArrayOfImages.push(value.image)
  //     })
  //   }
  // })

  // GET signedURLs for all the images
  // const imageCollection = await Promise.all(
  //   ArrayOfImages.map(async (item) => {
  //     const imageName = item.substring(item.lastIndexOf('/') + 1)
  //     const params = {
  //       Bucket: 'ai-generated-ui-contracts',
  //       Key: `26342f1f-c9cc-49b8-8e90-6a9def130a47/${imageName}`,
  //     }

  //     try {
  //       const url = await s3.getSignedUrlPromise('getObject', params)
  //       return url
  //     } catch (error) {
  //       console.error('Error getting S3 image URL:', error)
  //       throw error
  //     }
  //   })
  // )

  //update the components array and replace the existing URL's with signed URL's
  // const UpdatedUIArray = updatedArr.map((item, index) => {
  //   if (item?.props?.companyLogo) {
  //     const logo = imageCollection[0]
  //     const newProps = { ...item.props, companyLogo: logo }
  //     return { ...item, props: newProps }
  //   }

  //   if (Array.isArray(item?.props?.cardsContent)) {
  //     const _array = item.props.cardsContent.map((value, i) => {
  //       const _val = { ...value, image: imageCollection[i + 1] }
  //       return _val
  //     })
  //     const _newProps = { ...item.props, cardsContent: _array }
  //     return { ...item, props: _newProps }
  //   }

  //   //Temporary fix until backend issue for banner1 is fixed
  //   const bannerOneImages =
  //     item.component === 'banner1' &&
  //     Object.keys(item?.props?.cardContent)?.length !== 1

  //   if (bannerOneImages) {
  //     const _cardContent = Object?.values?.(item?.props?.cardContent)?.map(
  //       (item, i) => {
  //         if (item?.images?.[0]) {
  //           item.images[0] = imageCollection[i + 1]
  //         }

  //         return item
  //       }
  //     )
  //     const _newProps = { ...item?.props, cardContent: _cardContent }
  //     return { ...item, props: _newProps }
  //   }

  //   if (item.component === 'Banner3') {
  //     const images = item?.props?.cardContent?.[0]?.images?.map(
  //       (item, i) => imageCollection[i + 1]
  //     )

  //     console.log('images', images)

  //     const newCardContent = {
  //       0: { ...item?.props?.cardContent?.[0], images },
  //     }
  //     const _newProps = { ...item.props, cardContent: newCardContent }
  //     return { ...item, props: _newProps }
  //   }
  //   //Temporary  fix end

  //   return item
  // })

  // return UpdatedUIArray
  return { convertedData, styleConfig }
}

const initialState = {
  promptsHistory: [],
  promptsHistoryLoading: false,
  uiGeneratorData: null,
  isLoading: false,
  uiGeneratorLoading: false,
  prompt: '',
  processData: null,
  feedback: {
    value: '',
    text: '',
  },
  feedbackSubmitLoader: false,
  showFeedbackSuccess: false,
}

export { processDetailsConverter, initialState }
