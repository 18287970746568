import React from 'react'
import styles from './Loader.module.scss'
import { Text } from '../Text'
import { Separator } from '../Separator'
import { FlexView } from '../FlexView'

const Loader = ({ label = '' }) => {
  return (
    <FlexView alignItems="center">
      <FlexView className={styles.loaderBase}></FlexView>
      {label && (
        <>
          <Separator height={21} />
          <Text variant="p3" color="var(--color-white-30)">
            {label}
          </Text>
        </>
      )}
    </FlexView>
  )
}

export default Loader
