import React from 'react'
// import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// import { Card, Button } from 'react-bootstrap'
import CardSliderAirbnb from 'components/Slider/CardSlider/CardSliderAirbnb'
import CardSliderX4 from 'components/Slider/CardSlider/CardSliderX4'
import CardSliderNetflix from 'components/Slider/CardSlider/CardSliderNetflix'

const CardSlider = ({ props }) => {
  const { styleName, cardsContent, rows, visibleCards } = props

  switch (styleName) {
    case 'CATEGORY_AIRBNB':
      return (
        <CardSliderAirbnb
          cardsContent={cardsContent}
          rows={rows}
          visibleCards={visibleCards}
        />
      )
    case 'CATEGORY_X4':
      return (
        <CardSliderX4
          cardsContent={cardsContent}
          rows={rows}
          visibleCards={visibleCards}
        />
      )
    case 'CATEGORY_NETFLIX':
      return (
        <CardSliderNetflix
          cardsContent={cardsContent}
          rows={rows}
          visibleCards={visibleCards}
        />
      )
    default:
      return <div>Unexpected Banner</div>
  }

  // ;<div>
  //   {sectionContent && (
  //     <div>
  //       {sectionContent.title && (
  //         <h3 className="TH3" style={{ textIndent: 'center' }}>
  //           {sectionContent.title}
  //         </h3>
  //       )}

  //       {sectionContent.subtitle && (
  //         <h3 className="TR1">{sectionContent.subtitle}</h3>
  //       )}

  //       {sectionContent.text && <p className="TR1">{sectionContent.text}</p>}
  //     </div>
  //   )}
  //   <Slider>
  //     {cardsContent.map((card) => (
  //       <div key={card.id}>
  //         <Card className="slide-item">
  //           {card.image && <Card.Img variant="top" src={card.image} />}
  //         </Card>

  //         {(card.title ||
  //           card.subtitle ||
  //           card.description ||
  //           card.buttonLink) && (
  //           <>
  //             {card.title && <h3 className="TButtonBig">{card.title}</h3>}
  //             {card.subtitle && <h4 className="TButtonBig">{card.subtitle}</h4>}
  //             {card.description && <p className="TS1">{card.description}</p>}
  //             {card.buttonLink && card.buttonLabel && (
  //               <Button href={card.buttonLink}>{card.buttonLabel}</Button>
  //             )}
  //           </>
  //         )}
  //       </div>
  //     ))}
  //   </Slider>
  // </div>
}

export default CardSlider
