import classNames from 'classnames'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FlexView } from 'common/UI/FlexView'
import { Image } from 'common/UI/Image'
import { Loader } from 'common/UI/Loader'
import { Separator } from 'common/UI/Separator'
import { Text } from 'common/UI/Text'
import uiGeneratorSample from 'common/assets/images/files/ui-generator-sample.png'
import routes from 'lib/config/routes'
import { usePromptStore } from 'pages/Prompt/store'

import styles from './PromptsHistoryCard.module.scss'

const PromptsHistoryCard = ({ processId, prompt, status, createdAt }) => {
  const navigate = useNavigate()
  const { resetPrompts } = usePromptStore()
  const { id } = useParams()

  const handleHistoryClick = useCallback(() => {
    if (id !== processId) {
      resetPrompts()
      navigate(routes.PROMPT.replace(':id?', processId))
    }
  }, [id, processId, navigate, resetPrompts])

  const isActive = id === processId

  const cardWrapperClass = classNames(styles.cardWrapper, {
    [styles.isActive]: isActive,
  })
  const showLoader = status !== 'completed'

  return (
    <FlexView
      borderRadius={12}
      gutter={12}
      gap={8}
      className={cardWrapperClass}
      flexDirection="row"
      onClick={handleHistoryClick}
    >
      <FlexView
        borderRadius={8}
        backgroundColor="var(--color-black-dark)"
        className={styles.statusWrapper}
        justifyContent="center"
        alignItems="center"
      >
        {showLoader ? <Loader /> : <Image src={uiGeneratorSample} />}
      </FlexView>
      <Separator width={12} />
      <FlexView justifyContent="center" className={styles.contentWrapper}>
        <Text
          className={styles.prompt}
          variant="p3"
          color="var(--color-white)"
          ellipsis
        >
          {prompt}
        </Text>
        <Separator height={4} />
        <Text variant="p3" color="var(--color-white-40)">
          {createdAt}
        </Text>
      </FlexView>
    </FlexView>
  )
}

export default PromptsHistoryCard
