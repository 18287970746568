import { Container, Row, Col, Image, Button, Card } from 'react-bootstrap'
import styles from './Banner3.module.scss'
import { Text } from 'common/UI/Text'

function Banner3({ props }) {
  const { sectionContent, cardContent } = props
  const { action, footNote, images, nImages, subText, title } = cardContent?.[0]

  return (
    <div className={styles.banner3Wrapper}>
      <Text>{title}</Text>
      <Text className={styles.subText}>{subText}</Text>
      <div className={styles.imageWrapper}>
        {images?.map((image) => (
          <Image src={image} className={styles.image} />
        ))}
      </div>
      <div className={styles.footer}>
        <Text>{footNote}</Text>
        <Button className={styles.button}>{action}</Button>
      </div>
    </div>
  )
}

export default Banner3
