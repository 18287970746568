import React, { Suspense } from 'react'
import MobileApp from './components/MobileApp'
import AllComponents from './components/MobileAllComponents'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import AppSkeleton from 'AppSkeleton'
import Home from 'pages/Home'
import routes from 'lib/config/routes'
import './App.scss'
import Prompt from 'pages/Prompt'
import { GoogleOAuthProvider } from '@react-oauth/google'
import config from 'lib/config'
import PrivateRoute from 'modules/PrivateRoute'
import { hotjar } from 'react-hotjar';

const { HOTJAR_ID, HOTJAR_SNIPPET_VERSION } = config
hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);

function App() {
  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
      <Router>
        <Suspense fallback={<AppSkeleton />}>
          <Routes>
            <Route path="all" element={<AllComponents />} />
            <Route path="*" element={<p>No page</p>} />
            <Route path="/test" element={<MobileApp />}></Route>
            <Route path={routes.HOME} element={<Home />} />
            <Route
              path={routes.PROMPT}
              element={
                <PrivateRoute>
                  <Prompt />
                </PrivateRoute>
              }
            />
          </Routes>
        </Suspense>
      </Router>
    </GoogleOAuthProvider>
  )
}

export default App
