import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Row, Col, Card, Container } from 'react-bootstrap';
import StyleService from '../../services/StyleService';

const Category1 = ( props ) => {
    
  const styleName = props.styleName;

  const styleJson = StyleService.getStyleJsonFromName(styleName);
  const cardStyle = StyleService.getStylesFromProps(styleJson,'Card');
  const imgStyle= StyleService.getStylesFromProps(styleJson,'Image');
  const sectionStyle= StyleService.getStylesFromProps(styleJson,'Section');  
  const slidersettings =  StyleService.getStylesFromProps(styleJson,'sliderSettings')
   
    const cardsliderdata = props.cardsContent;
    const sectiondata = props.sectionContent;
  
    const settings = {
      dots: true, // Show navigation dots
      infinite: false, // Enable infinite looping
      slidesToShow: 1, // Number of cards to show per slide
      slidesToScroll: 1,// Number of cards to scroll per slide   
      rows:slidersettings.rows,
      slidesPerRow: slidersettings.columns
      
    };

    return (

      <div style={sectionStyle}>

      
      {sectiondata && (
      
      <div>
        {sectiondata.title && (
          <h2 className="TH3">
            {sectiondata.title}
          </h2>
        )}
      
        {sectiondata.subtitle && (
          <h3>
            {sectiondata.subtitle}
          </h3>
        )}
      
        {sectiondata.subtitle && (
          <p>
            {sectiondata.text}
          </p>
        )}
      </div>
      )}
      <Slider {...settings}>
        
        {cardsliderdata.map((card) => (
          <div key={card.id}>
            <Card  style={cardStyle} className="slide-item">
              <Container>
                  <Row>
                    <Col xs={4} md={4} lg={3}>
                        {card.image && (   <Card.Img width="20px" height="20px" src={card.image} style={imgStyle}  />)}
                  
                        {card.svg && 
                                (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                <path d= {card.svg}/>
                                    </svg>)}
                    </Col>
                    <Col xs={8} md={8} lg={9}>  
                      {card.description && ( <p className='TR2' style={{"padding":"0px","overflow":"hidden"}}>{card.description}</p>)}
                     </Col>
                </Row>
              </Container>
            
              
            </Card>
            
          </div>
        ))}
        
      </Slider>
      </div>
    );
};

export default Category1;
