import React from 'react'
import styles from './Banner1.module.scss'
import { Image } from 'common/UI/Image'
import { Button } from 'common/UI/Button'

const DefaultBanner = ({ cardContent }) => {
  return (
    <div className={styles.defaultBanner}>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>{cardContent?.title}</div>
        <div>{cardContent?.subtitle}</div>
        <Button
          href={cardContent?.buttonLink}
          style={{ backgroundColor: 'var(--CSecondary1)' }}
        >
          {cardContent?.buttonLabel}
        </Button>
      </div>
      <div className={styles.imageWrapper}>
        <Image className={styles.image} src={cardContent?.imageUrl} />
      </div>
    </div>
  )
}

export default DefaultBanner
