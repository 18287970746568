import React from 'react'
import { Form, FormControl, Container, Row, Col, Button } from 'react-bootstrap'
import { UilSearch, UilCalender, UilUser } from '@iconscout/react-unicons'
import StyleService from '../../services/StyleService'

const Search2 = (props) => {
  const sectionStyle = StyleService.getStylesFromProps(props, 'Section')

  return (
    <div>
      <Container className="search2">
        <Row style={{ width: '100%', margin: '6px 0px' }}>
          <Col xs={2} md={2}>
            <div>
              <UilSearch size={20} />
            </div>
          </Col>
          <Col xs={10} md={10}>
            <Form>
              <FormControl
                type="text"
                placeholder="Rishikesh"
                className="flex-grow custom-form-control"
              />
            </Form>
          </Col>
        </Row>
        <Row style={{ width: '100%', margin: '6px 0px' }}>
          <Col xs={2} md={2}>
            <div>
              <UilCalender size={20} />
            </div>
          </Col>
          <Col xs={10} md={10}>
            <Form>
              <FormControl
                type="text"
                placeholder="Tue, Apr 04 - Fri, Apr 07"
                className="flex-grow custom-form-control"
              />
            </Form>
          </Col>
        </Row>
        <Row style={{ width: '100%', margin: '6px 0px' }}>
          <Col xs={2} md={2}>
            <div>
              <UilUser size={20} />
            </div>
          </Col>
          <Col xs={10} md={10}>
            <Form>
              <FormControl
                type="text"
                placeholder="1 room . 2 Adults . 0 childern"
                className="flex-grow custom-form-control"
              />
            </Form>
          </Col>
        </Row>
        <Row style={{ width: '100%', margin: '6px 0px' }}>
          <Button style={{ backgroundColor: 'var(--CSecondary1)' }}>
            Search
          </Button>
        </Row>
      </Container>
    </div>
  )
}

export default Search2
