import React from 'react'
import styles from './Text.module.scss'
import classNames from 'classnames'

const Text = ({
  as: Component = 'span',
  variant = 'p2',
  className,
  children,
  color = 'var(--color-white)',
  textAlign,
  ellipsis,
  title,
}) => {
  const textStyles = {
    color,
    textAlign,
  }
  const textWrapperClass = classNames(styles.textWrapper, {
    [className || '']: true,
    [styles[variant]]: variant,
    [styles.ellipsis]: ellipsis,
  })

  return (
    <Component style={textStyles} className={textWrapperClass} title={title}>
      {children}
    </Component>
  )
}

export default Text
