import {
  // UilUserCircle,
  // UilShoppingCartAlt,
  UilUser,
  UilLocationPoint,
  UilArrowUpRight,
} from '@iconscout/react-unicons'
import React from 'react'
import styles from './Header5.module.scss'
import { Text } from 'common/UI/Text'

const Header5 = ({ props }) => {
  const {
    // companyLogo,
    //  companyName,
    // showWallet,
    showProfile,
    showLocation,
  } = props

  return (
    <div className={styles.header5Wrapper}>
      {showLocation ? (
        <div className={styles.locationWrapper}>
          <UilLocationPoint size={24} />
          <div className={styles.locationContentWrapper}>
            <Text className={styles.textTitle}>
              Work{' '}
              <div className={styles.titleArrow}>
                <UilArrowUpRight size={16} />
              </div>
            </Text>
            <Text className={styles.textDesc}>
              911, 9th Floor, Dev Plaza, Andheri West, Mumbai
            </Text>
          </div>
        </div>
      ) : null}
      <div>
        {/* {showWallet ? (
          <div>
            <UilShoppingCartAlt size={20} />
          </div>
        ) : null}{' '} */}
        {showProfile ? (
          <div>
            <UilUser size={24} />
          </div>
        ) : null}
      </div>
      {/* <Col xs={2} className="icon-container">
              <UilShoppingCartAlt size={20} /> 
          </Col>
          <Col xs={2} className="icon-container">
              <UilUser size={20} />             
          </Col>   */}
    </div>
  )
}

export default Header5
