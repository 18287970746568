import { Navbar, Nav, Col, Row, Container } from 'react-bootstrap'
import { UilBars, UilShoppingCartAlt, UilUser } from '@iconscout/react-unicons'
import styles from './Header3.module.scss'

import React from 'react'

function Header3({ props }) {
  const { companyLogo, companyName, showCart, showProfile } = props
  return (
    <div className={styles.header3Wrapper}>
      <Container style={{ padding: '0px' }}>
        <Navbar className="nav-bar" style={{ padding: '0px' }}>
          <Row style={{ width: '100%', margin: '0px' }}>
            {/* <Col xs={1}  className="icon-container"> */}
            <Col
              xs={1}
              style={{
                padding: '0px',
                display: 'flex',
                alignItems: 'center',
                gap: 'var(--Sp6)',
              }}
            >
              <UilBars size={20} />
            </Col>
            {/* <Col xs={8} className="header-container" > */}
            <Col
              xs={8}
              style={{
                padding: '0px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 'var(--Sp6)',
              }}
            >
              {companyLogo && (
                <img className="icon-image-container" src={companyLogo} />
              )}

              {companyName && (
                <h3 className="TR1" style={{ margin: '0px' }}>
                  {companyName}
                </h3>
              )}
            </Col>
            <Col
              xs={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 'var(--Sp6)',
              }}
            >
              {showCart ? (
                <div>
                  <Nav.Link>
                    <UilShoppingCartAlt size={20} />
                  </Nav.Link>
                </div>
              ) : null}
              {showProfile ? (
                <div>
                  <Nav.Link>
                    <UilUser size={20} />
                  </Nav.Link>
                </div>
              ) : null}
            </Col>
            {/* <Col xs={2} className="icon-container">
              <UilShoppingCartAlt size={20} /> 
          </Col>
          <Col xs={2} className="icon-container">
              <UilUser size={20} />             
          </Col>   */}
          </Row>
        </Navbar>
      </Container>
    </div>
  )
}

export default Header3
