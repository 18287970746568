import { Navbar, Container, Row, Col } from 'react-bootstrap'
import {
  UilWallet,
  UilLocationPoint,
  UilUserCircle,
  UilAngleDown,
} from '@iconscout/react-unicons'
import React from 'react'

import styles from './Header6.module.scss'

const Header6 = ({ props }) => {
  const { navDropdown = { title: 'Title' } } = props

  return (
    <div className={styles.header6Wrapper}>
      <Container>
        <Navbar className="nav-bar">
          <Row style={{ width: '100%' }}>
            <Col xs={1}>
              <div style={{ color: 'var(--CPrimary1)' }}>
                <UilUserCircle size={20} />
              </div>
            </Col>
            <Col xs={8}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                }}
              >
                <DropdownTitle heading={navDropdown?.title} />
              </div>
            </Col>
            <Col xs={3}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                  color: 'var(--CPrimary1)',
                }}
              >
                <UilWallet size={20} />
              </div>
            </Col>
          </Row>
        </Navbar>
      </Container>
    </div>
  )
}

const DropdownTitle = ({ heading }) => (
  <div>
    <span className="TS1" style={{ color: 'black' }}>
      <UilLocationPoint size={16} />
      {heading}
      <UilAngleDown size={20} />
    </span>
  </div>
)

export default Header6
