import { FlexView } from 'common/UI/FlexView'
import { Separator } from 'common/UI/Separator'
import { Text } from 'common/UI/Text'
import { getRelativeTime } from 'lib/utils/date'

import PromptsHistoryCard from './HistoryCard'
import useLogic from './PromptsHistory.logic'
import styles from './PromptsHistory.module.scss'

const PromptsHistory = () => {
  const {
    processId,
    showNewPromptCard,
    promptsHistory,
    promptsHistoryLoading,
    handleAddNewPrompt,
  } = useLogic()

  return (
    <FlexView className={styles.promptsHistoryWrapper} gutter={12}>
      <FlexView gutter={12}>
        <Text variant="p2" color="var(--color-grey-light)">
          History
        </Text>
      </FlexView>
      <Separator height={12} />
      <FlexView className={styles.listWrapper} gutter={12}>
        {promptsHistoryLoading ? (
          <Text variant="p3">Loading...</Text>
        ) : (
          <>
            {showNewPromptCard && (
              <FlexView
                borderRadius={12}
                backgroundColor="var(--color-grey-dark)"
                gutter={12}
                gap={8}
                className={styles.newPromptCard}
                onClick={handleAddNewPrompt}
                flexDirection='row'
              >
                <FlexView width={48} height={48} alignItems="center" justifyContent="center">
                  <Text variant="h3" color="var(--color-primary)">
                    +
                  </Text>
                </FlexView>
                <Separator width={12} />
                <FlexView justifyContent="center">
                  <Text
                    className={styles.prompt}
                    variant="p3"
                    color="var(--color-white)"
                    ellipsis
                  >
                    Start New
                  </Text>
                  <Separator height={4} />
                  <Text variant="p3" color="var(--color-white-40)">
                    Enter a prompt
                  </Text>
                </FlexView>
              </FlexView>
            )}
            {promptsHistory?.map(({ processId, prompt, status, createdAt }) => (
              <PromptsHistoryCard
                key={processId}
                processId={processId}
                prompt={prompt}
                status={status}
                createdAt={getRelativeTime(createdAt)}
              />
            ))}
          </>
        )}
      </FlexView>
    </FlexView>
  )
}

export default PromptsHistory
