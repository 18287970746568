import { UilBars, UilUsdCircle, UilSuitcase } from '@iconscout/react-unicons'
import styles from './Header4.module.scss'

import React from 'react'

function Header4({ props }) {
  const {
    backgroundImage,
    companyName,
    showBackgroundImage,
    showCash,
    showSuitcase,
  } = props

  return (
    <div
      className={styles.headerWrapper}
      style={{
        ...(showBackgroundImage
          ? { backgroundImage: `url(${backgroundImage})` }
          : {}),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100%',
      }}
    >
      <div className={styles.leftContentWrapper}>
        <UilBars size={20} />
        <div className={styles.companyName} title={companyName}>
          {companyName}
        </div>
      </div>
      <div className={styles.pillsWrapper}>
        {showCash ? (
          <div className={styles.pillContainer}>
            <UilUsdCircle size={16} style={{ color: 'white' }} />
            <div className={styles.ts1}>Cash</div>
          </div>
        ) : null}
        {showSuitcase ? (
          <div className={styles.pillContainer}>
            <UilSuitcase size={16} style={{ color: 'white' }} />
            <div className={styles.ts1}>Biz</div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default Header4
