import React from 'react'
import { Form, FormControl, Container, Row, Col } from 'react-bootstrap'
import { UilSearch, UilSlidersVAlt } from '@iconscout/react-unicons'
import styles from './Search1.module.scss'

const Search1 = ({ props }) => {
  const { searchText, searchSubtext } = props

  return (
    <div className={styles.headerWrapper}>
      <UilSearch
        style={{ color: 'var(--CPrimary1)' }}
        size={20}
        className={styles.searchIcon}
      />
      <Form>
        <FormControl
          type="text"
          placeholder={searchText}
          className="flex-grow custom-form-control"
        />
      </Form>
      <UilSlidersVAlt
        style={{ transform: 'rotate(90deg)', color: 'var(--CPrimary1)' }}
        size={20}
        className={styles.filterIcon}
      />
    </div>
  )
}

const PlaceHolder = ({ searchText, searchSubtext }) => (
  <div>
    <p className="TH3" style={{ margin: '0px', padding: '0px' }}>
      {searchText}
    </p>

    <p className="R2" style={{ margin: '0px', padding: '0px' }}>
      {searchSubtext}
    </p>
  </div>
)

export default Search1
