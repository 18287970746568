import React from 'react'
import styles from './Image.module.scss'
import classNames from 'classnames'

const Image = ({ width, height, src, alt = '', className }) => {
  const imageStyles = {
    height,
    width,
  }
  const imageClass = classNames(styles.iamgeBase, {
    [className || '']: className,
  })

  return <img src={src} style={imageStyles} alt={alt} className={imageClass} />
}

export default Image
