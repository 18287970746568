import classNames from 'classnames'
import React from 'react'
import styles from './Button.module.scss'

const Button = ({
  variant = 'secondary',
  size = 'sm',
  disabled = false,
  loading = false,
  label,
  className = '',
  icon = '',
  iconSize,
  ...rest
}) => {
  const buttonClass = classNames(styles.buttonBase, {
    [styles[variant]]: variant,
    [styles[size]]: size,
    [styles.disabled]: disabled || loading,
    [className || '']: className,
  })

  const iconStyle = {
    width: iconSize,
    height: iconSize,
  }

  return (
    <button className={buttonClass} disabled={disabled} {...rest}>
      {icon && (
        <img
          alt=""
          className={styles.iconWrapper}
          src={icon}
          style={iconStyle}
        />
      )}
      {label}
    </button>
  )
}

export default Button
