import React from 'react';
import { Card,Container,Row,Col } from 'react-bootstrap';
import  StyleService from '../../services/StyleService';
import {UilStar} from '@iconscout/react-unicons';

function Card1 (props){

  const styleName = props.styleName;
  const styleJson = StyleService.getStyleJsonFromName(styleName);
  const cardStyle = StyleService.getStylesFromProps(styleJson,'Card');
  const imgStyle= StyleService.getStylesFromProps(styleJson,'Image');
  const sectionStyle= StyleService.getStylesFromProps(styleJson,'Section');
  
  const carddata = props.cardsContent;
  const sectiondata = props.sectionContent;
  
  return (

    <div style={sectionStyle} >
      {sectiondata && (

      <div>

        {sectiondata.title && (
          <h2 className="TH2" >
            {sectiondata.title}
          </h2>
        )}

        {sectiondata.subtitle && (
          <h3 className="TH3" >
            {sectiondata.subtitle}
          </h3>
        )}

        {sectiondata.text && (
          <p className="TR2" >
            {sectiondata.text}
          </p>
        )}
      </div>
    )} 
      {carddata.map((carditem, index) => (
        <Card  key={index}  style={cardStyle} >     
        <div>
          <Container style={{"margin":"0px","padding":"0px"}}> 
            <Row style={{"margin":"0px","padding":"0px"}}> 
              <Col xs={6} style={{"margin":"0px","padding":"0px"}}>                
                {carditem.image && <Card.Img  src={carditem.image}  style={imgStyle} alt={carditem.title} />} 
              </Col>
              <Col xs={6} style={{"margin":"0px","padding":"0px"}}>
                <Card.Body style={{"margin":"0px","padding":"0px"}}>
                  <h3 className="TButtonBig" >
                    {carditem.title}
                  </h3>
                  <h4 className="TS1">
                    {carditem.showStar && <UilStar size={16} color="blue" />}
                    {carditem.subtitle}
                  </h4>
                  <p className="TS1" >{carditem.description}</p>                  
                </Card.Body>
              </Col>
            </Row>
          </Container>    
          </div> 
        </Card>
      )
      )}    
    </div>
  ); 
 };

export default Card1;
