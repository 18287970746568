import React from 'react'

const Separator = ({
  height = 0,
  width = 1,
  backgroundColor = 'transparent',
}) => {
  const style = {
    height,
    width,
    backgroundColor,
  }

  return <div style={style}></div>
}

export default Separator
