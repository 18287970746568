import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { FlexView } from 'common/UI/FlexView'
import { Separator } from 'common/UI/Separator'
import Header from 'modules/Header'

import styles from './Prompt.module.scss'
import PromptEntry from './components/PromptEntry/PromptEntry'
import PromptsHistory from './components/PromptsHistory'
import { UIGenerator } from './components/UIGenerator'
import { usePromptStore } from 'pages/Prompt/store'
import { Feedback } from 'pages/Prompt/components/Feedback'

const Prompt = () => {
  const { processData, resetPrompts } = usePromptStore()
  const { id } = useParams()

  const showPromptInput =
    !id || (id && processData && processData.status !== 'completed')

  useEffect(() => {
    return () => {
      resetPrompts()
    }
  }, [])

  return (
    <FlexView inherit className={styles.promptWrapper}>
      <Header />
      <FlexView inherit flexDirection="row" className={styles.contentWrapper}>
        <PromptsHistory />
        <FlexView inherit justifyContent="center" alignItems="center">
          <UIGenerator />
          {showPromptInput ? (
            <>
              <Separator height={64} />
              <PromptEntry showPromptInput={showPromptInput} />
              <Separator height={32} />
            </>
          ) : null}
        </FlexView>
        {!showPromptInput ? (
          <div className={styles.feedbackWrapper}>
            <Feedback />
          </div>
        ) : null}
      </FlexView>
    </FlexView>
  )
}

export default Prompt
