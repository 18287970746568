import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Button, Card } from 'react-bootstrap'
import StyleService from '../../services/StyleService'

const CardSlider2 = (props) => {
  const styleName = props.styleName

  const styleJson = StyleService.getStyleJsonFromName(styleName)
  const cardStyle = StyleService.getStylesFromProps(styleJson, 'Card')
  const imgStyle = StyleService.getStylesFromProps(styleJson, 'Image')
  const sectionStyle = StyleService.getStylesFromProps(styleJson, 'Section')
  const slidersettings = StyleService.getStylesFromProps(
    styleJson,
    'sliderSettings'
  )

  const cardsliderdata = props.cardsContent
  const sectiondata = props.sectionContent

  const settings = {
    dots: true, // Show navigation dots
    infinite: false, // Enable infinite looping
    slidesToShow: 1, // Number of cards to show per slide
    slidesToScroll: 1, // Number of cards to scroll per slide
    rows: slidersettings.rows,
    slidesPerRow: slidersettings.columns,
  }

  return (
    <div style={sectionStyle}>
      {sectiondata && (
        <div>
          {sectiondata.title && <h2>{sectiondata.title}</h2>}

          {sectiondata.subtitle && <h3>{sectiondata.subtitle}</h3>}

          {sectiondata.text && <p>{sectiondata.text}</p>}
        </div>
      )}

      <Slider {...settings}>
        {cardsliderdata.map((card) => (
          <div key={card.id}>
            <Card style={cardStyle} className="slide-item">
              <Card.Body>
                <Card.Title>{card.title}</Card.Title>
                <Card.Text>{card.description}</Card.Text>
                {card.buttonLink && card.buttonLabel && (
                  <Button href={card.buttonLink}>{card.buttonLabel}</Button>
                )}
              </Card.Body>
              <Card.Img variant="bottom" src={card.image} style={imgStyle} />
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default CardSlider2
