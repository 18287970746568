import React, { lazy, Suspense } from 'react'

import { FlexView } from 'common/UI/FlexView'
import { Image } from 'common/UI/Image'
import desktopBgTop from 'common/assets/images/files/desktop-bg-top.png'
import useDeviceType from 'common/hooks/useDeviceType'
import Header from 'modules/Header'

import styles from './Home.module.scss'
import { Loader } from 'common/UI/Loader'

const Overview = lazy(() => import('./components/Overview'))
const DesignSamples = lazy(() =>
  import('./components/DesignSamples/DesignSamples')
)
const Features = lazy(() => import('./components/Features/Features'))
const TargetAudience = lazy(() =>
  import('./components/TargetAudience/TargetAudience')
)
const RoadMap = lazy(() => import('./components/RoadMap/RoadMap'))
const ResearchFindings = lazy(() =>
  import('./components/ResearchFindings/ResearchFindings')
)
const Industries = lazy(() => import('./components/Industries/Industries'))
const LaunchCTA = lazy(() => import('./components/LaunchCTA/LaunchCTA'))
const FAQs = lazy(() => import('./components/FAQs/FAQs'))
const JoinUXRevolutionCTA = lazy(() =>
  import('./components/JoinUXRevolutionCTA/JoinUXRevolutionCTA')
)
const Footer = lazy(() => import('./components/Footer/Footer'))
const Capabilities = lazy(() => import('./components/Capabilities/Capabilities'))

const Home = () => {
  const { isMobile } = useDeviceType()

  return (
    <Suspense
      fallback={
        <FlexView justifyContent="center" alignItems="center" backgroundColor="var(--color-black-light)" className={styles.loaderWrapper}>
          <Loader label="Loading..."/>
        </FlexView>
      }
    >
      <FlexView
        className={styles.homeWrapper}
        backgroundColor="var(--color-black-light)"
      >
        <Header />
        <Image src={desktopBgTop} className={styles.desktopBgTop} />
        <FlexView
          alignItems="center"
          backgroundColor="var(--color-black-light)"
        >
          <FlexView width={isMobile ? '100%' : 1160} zIndex={99}>
            <Overview />
            <DesignSamples />
            <Features />
            <TargetAudience />
            <RoadMap />
            <ResearchFindings />
            {!isMobile && <>
                <Capabilities />
                <Industries />
            </>}
            <LaunchCTA />
            <FAQs />
            {!isMobile && <JoinUXRevolutionCTA />}
          </FlexView>
          <Footer />
        </FlexView>
      </FlexView>
    </Suspense>
  )
}

export default Home
