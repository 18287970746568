import interacxion from 'common/assets/images/files/interacxion.png'
import React, { Fragment } from 'react'
import styles from './Header.module.scss'
import Text from 'common/UI/Text/Text'
import { FlexView } from 'common/UI/FlexView'
import { Image } from 'common/UI/Image'
import useLogic from './Header.logic'
import { Button } from 'common/UI/Button'
import { Separator } from 'common/UI/Separator'
import icons from 'common/assets/icons'
import useDeviceType from 'common/hooks/useDeviceType'

const sections = [
  {
    title: 'View roadmap',
    hash: 'view-roadmap',
  },
  {
    title: 'For Designers',
    hash: 'for-designers',
  },
]
const socialLinks = [
  {
    icon: icons.twitterIcon,
    url: 'https://twitter.com/interacxion',
  },
  {
    icon: icons.linkedInIcon,
    url: ' https://www.linkedin.com/company/interacxion/',
  },
]
const Header = () => {
  const { isMobile } = useDeviceType()
  const {
    login,
    scrollToSection,
    name,
    handleLogoClick,
    handleLogout,
    isLoggedIn,
    createdAt,
    prompt,
  } = useLogic()

  return (
    <FlexView
      className={styles.headerWrapper}
      justifyContent="space-between"
      gutter={isMobile ? 20 : 24}
      gap={18}
      flexDirection="row"
    >
      <button onClick={handleLogoClick}>
        <div className={styles.logoWrapper}>
          <Image
            src={isMobile ? icons.interacxionLogo : interacxion}
            width={isMobile ? 24 : 192}
            height={24}
          />
          {!isMobile && (
            <>
              <Separator height={8} />
              <Text variant="p3" textAlign="left" color="var(--color-white)">
                Beta Preview
              </Text>
            </>
          )}
        </div>
      </button>
      {prompt ? (
        <div className={styles.promptWrapper}>
          <Text
            textAlign="center"
            variant="p2"
            className={styles.prompt}
            title={prompt}
          >
            {prompt}
          </Text>
          <Text textAlign="center" variant="p2" color="var(--color-white-40)">
            {createdAt}
          </Text>
        </div>
      ) : null}
      <FlexView className={styles.userDetails} alignItems="center">
        {isLoggedIn ? (
          <FlexView flexDirection="row" alignItems="center">
            <Text variant="p2">{name}</Text>
            <Separator width={24} />
            <Button
              variant="secondary"
              size="xs"
              onClick={handleLogout}
              label="Sign out"
            />
          </FlexView>
        ) : (
          <FlexView flexDirection="row" alignItems="center">
            {!isMobile &&
              sections.map(({ title, hash }) => (
                <Fragment key={hash}>
                  <FlexView
                    flexDirection="row"
                    onClick={() => scrollToSection(hash)}
                    key={hash}
                    className={styles.link}
                  >
                    <Text variant="p2">{title}</Text>
                  </FlexView>
                  <Separator width={24} />
                </Fragment>
              ))}
            {socialLinks.map(({ icon, url }) => (
              <Fragment key={url}>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.link}
                >
                  <Image src={icon} />
                </a>
                <Separator width={24} />
              </Fragment>
            ))}
            <Button
              iconSize={isMobile ? 16 : 18}
              icon={icons.googleIconGreen}
              variant="primaryOutlined"
              label={isMobile ? 'Sign in' : 'Sign in via Google'}
              onClick={login}
            />
          </FlexView>
        )}
      </FlexView>
    </FlexView>
  )
}

export default Header
