import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Card, Button } from 'react-bootstrap'
import StyleService from '../../services/StyleService'

const CardSlider4 = (props) => {
  const styleName = props.styleName

  const styleJson = StyleService.getStyleJsonFromName(styleName)
  const cardStyle = StyleService.getStylesFromProps(styleJson, 'Card')
  const imgStyle = StyleService.getStylesFromProps(styleJson, 'Image')
  const sectionStyle = StyleService.getStylesFromProps(styleJson, 'Section')

  const slidersettings = StyleService.getStylesFromProps(
    styleJson,
    'sliderSettings'
  )

  // const slidersettings = props.sliderSettings;

  const cardsliderdata = props.cardsContent
  const sectiondata = props.sectionContent

  const settings = {
    dots: true, // Show navigation dots
    infinite: false, // Enable infinite looping
    slidesToShow: slidersettings.slideToShow, // Number of cards to show per slide
    slidesToScroll: slidersettings.slideToScroll, // Number of cards to scroll per slide
    rows: slidersettings.rows,
    slidesPerRow: slidersettings.columns,
  }

  return (
    <div style={sectionStyle}>
      {sectiondata && (
        <div>
          {sectiondata.title && (
            <h3 className="TH3" style={{ textIndent: 'center' }}>
              {sectiondata.title}
            </h3>
          )}

          {sectiondata.subtitle && (
            <h3 className="TR1">{sectiondata.subtitle}</h3>
          )}

          {sectiondata.text && <p className="TR1">{sectiondata.text}</p>}
        </div>
      )}
      <Slider {...settings}>
        {cardsliderdata.map((card) => (
          <div key={card.id}>
            <Card style={cardStyle} className="slide-item">
              {card.image && (
                <Card.Img variant="top" src={card.image} style={imgStyle} />
              )}
              <>
                {(card.title ||
                  card.subtitle ||
                  card.description ||
                  card.buttonLink) && (
                  <Card.Body style={cardStyle}>
                    {card.title && <h3 className="TButtonBig">{card.title}</h3>}
                    {card.subtitle && (
                      <h4 className="TButtonBig">{card.subtitle}</h4>
                    )}
                    {card.description && (
                      <p className="TS1" style={{ color: cardStyle.color }}>
                        {card.description}
                      </p>
                    )}
                    {card.buttonLink && card.buttonLabel && (
                      <Button
                        href={card.buttonLink}
                        style={{
                          backgroundColor: cardStyle.color,
                          color: 'var(--CGray6)',
                        }}
                      >
                        {card.buttonLabel}
                      </Button>
                    )}
                  </Card.Body>
                )}
              </>
            </Card>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default CardSlider4
