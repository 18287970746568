import { FlexView } from 'common/UI/FlexView'
import { Loader } from 'common/UI/Loader'
import { Separator } from 'common/UI/Separator'
import { Text } from 'common/UI/Text'

import styles from './CookingDesignsLoader.module.scss'

const CookingDesignsLoader = () => {
  return (
    <FlexView
      borderRadius={24}
      gap={42}
      gutter={72}
      className={styles.cookingDesignsLoaderWrapper}
      backgroundColor="var(--color-black-dark)"
      alignItems="center"
      justifyContent="center"
    >
      <Text variant="h1" color="var(--color-grey-dark)">
        Cooking Designs
      </Text>
      <Separator height={42} />
      <Text variant="p2" color="var(--color-grey)">
        It usually takes about 5-7 mins
      </Text>
      <Separator height={8} />
      <Text variant="p2" color="var(--color-grey)">
        You’ll be notified on email when done
      </Text>
      <Separator height={42} />
      <Loader />
    </FlexView>
  )
}

export default CookingDesignsLoader
