import { getRelativeTime } from 'lib/utils/date'
import { usePromptStore } from 'pages/Prompt/store'

const useActivePrompt = (id) => {
  const { promptsHistory, prompt, processData } = usePromptStore()

  const activePromptHistory = promptsHistory?.find(
    ({ processId }) => processId === id
  )

  const activePrompt =
    processData?.processId === id
      ? processData?.prompt
      : activePromptHistory?.prompt || prompt

  const activePromptCreatedAt = getRelativeTime(activePromptHistory?.createdAt)
  const activeFeedback =
    activePromptHistory?.adminFeedback || processData?.adminFeedback
  const activeComment =
    activePromptHistory?.adminComment || processData?.adminComment

  const shouldRefreshHistory = !!(
    processData?.status &&
    activePromptHistory?.status &&
    activePromptHistory?.status !== processData?.status
  )

  window.console.log('processData?.status', processData?.status)

  return {
    prompt: activePrompt,
    createdAt: activePromptCreatedAt,
    activeFeedback,
    activeComment,
    shouldRefreshHistory,
  }
}

export { useActivePrompt }
