import useAuthHeaders from 'common/hooks/useAuthHeaders'
import routes from 'lib/config/routes'
import { usePromptStore } from 'pages/Prompt/store'
import { useCallback, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const useLogic = () => {
  const { id } = useParams()
  const headers = useAuthHeaders()
  const {
    resetPrompts,
    promptsHistory,
    promptsHistoryLoading,
    getPromptsHistory,
    resetPromptData,
  } = usePromptStore()

  const navigate = useNavigate()

  useEffect(() => {
    getPromptsHistory({ headers })
  }, [headers])

  const handleAddNewPrompt = useCallback(() => {
    navigate(routes.PROMPT.replace(':id?', ''), {
      replace: true,
    })
    resetPrompts()
    resetPromptData()
  }, [navigate])

  const showNewPromptCard = true

  return {
    showNewPromptCard,
    promptsHistoryLoading,
    promptsHistory,
    processId: id,
    handleAddNewPrompt,
  }
}

export default useLogic
