import relativeTime from 'dayjs/plugin/relativeTime'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

const getRelativeTime = (date) => {
  dayjs.extend(advancedFormat)
  dayjs.extend(customParseFormat)
  dayjs.extend(relativeTime)
  dayjs.extend(utc)

  const dateObj = dayjs.utc(date)

  return dayjs(dateObj).fromNow()
}

export { getRelativeTime }
