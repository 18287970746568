import useAuthActions from 'common/hooks/useAuthActions'
import routes from 'lib/config/routes'
import { useHomeStore } from 'pages/Home/store'
import { useParams } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'
import { useActivePrompt } from 'common/hooks/useActivePrompt'

const useLogic = () => {
  const { userInfo, isLoggedIn, logout } = useHomeStore()
  const navigate = useNavigate()
  const { login } = useAuthActions()

  const { id } = useParams()
  const { prompt, createdAt } = useActivePrompt(id)
  window.console.log('prompt, createdAt', { prompt, createdAt })
  const handleLogout = () => {
    logout()
  }

  const handleLogoClick = () => {
    navigate(routes.INITIAL)
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId)
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      })
    }
  }

  return {
    name: userInfo?.name,
    handleLogout,
    handleLogoClick,
    isLoggedIn,
    scrollToSection,
    login,
    prompt,
    createdAt,
  }
}

export default useLogic
