import { FlexView } from 'common/UI/FlexView'
import styles from './Feedback.module.scss'
import { Separator } from 'common/UI/Separator'
import { Input } from 'common/UI/Input'
import { IconButton } from 'common/UI/IconButton'
import { Image } from 'common/UI/Image'
import icons from 'common/assets/icons'
import { usePromptStore } from 'pages/Prompt/store'
import { Text } from 'common/UI/Text'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useAdminHeaders from 'common/hooks/useAdminHeaders'
import { useActivePrompt } from 'common/hooks/useActivePrompt'
import useAuthHeaders from 'common/hooks/useAuthHeaders'

const Feedback = () => {
  const { id: processId } = useParams()
  const {
    feedback,
    setFeedback,
    showFeedbackSuccess,
    feedbackSubmitLoader,
    submitFeedback,
    getPromptsHistory,
  } = usePromptStore()
  const headers = useAdminHeaders()
  const authHeaders = useAuthHeaders()
  const { activeFeedback } = useActivePrompt(processId)

  const handleSubmitFeedback = useCallback(async () => {
    const { text, value } = feedback
    const body = {
      process_id: processId,
      feedback: value,
      comment: text,
    }
    await submitFeedback({ headers, body })
    getPromptsHistory({ headers: authHeaders })
  }, [
    processId,
    submitFeedback,
    feedback,
    headers,
    getPromptsHistory,
    authHeaders,
  ])
  const feedbackValue = feedback?.value || activeFeedback

  useEffect(() => {
    if (feedback?.value) {
      handleSubmitFeedback()
    }
  }, [feedback?.value])

  return (
    <FlexView
      backgroundColor="var(--color-black)"
      borderRadius={12}
      gutter={8}
      gap={8}
      className={styles.feedbackWrapper}
    >
      <FlexView flexDirection="row">
        <IconButton
          onClick={() => {
            setFeedback({ key: 'value', value: 'like' })
          }}
          selected={feedbackValue === 'like'}
        >
          👍🏼
        </IconButton>
        <Separator width={8} />
        <IconButton
          selected={feedbackValue === 'dislike'}
          onClick={() => {
            setFeedback({ key: 'value', value: 'dislike' })
          }}
        >
          👎🏻
        </IconButton>
      </FlexView>
      <Separator height={8} />
      {showFeedbackSuccess ? (
        <FlexView gap={8}>
          <Text variant="p3" textAlign="center" color="var(--color-grey)">
            Thanks for your feedback!
          </Text>
        </FlexView>
      ) : (
        <FlexView className={styles.inputWrapper}>
          <Input
            className={styles.input}
            placeholder="Help us improve, leave feedback"
            multiline
            value={feedback.text}
            onChange={(e) =>
              setFeedback({ key: 'text', value: e.target.value })
            }
          >
            <IconButton
              gutter={16}
              gap={12}
              className={styles.iconButton}
              variant="primary"
              disabled={!feedback.text}
              loading={feedbackSubmitLoader}
              onClick={handleSubmitFeedback}
            >
              <Image height={8} width={11} src={icons.tickBlackIcon} alt="" />
            </IconButton>
          </Input>
        </FlexView>
      )}
    </FlexView>
  )
}

export default Feedback
