import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { Image } from 'common/UI/Image'
import icons from 'common/assets/icons'
import useAuthHeaders from 'common/hooks/useAuthHeaders'
import useAutoResizeTextArea from 'common/hooks/useAutoResizeTextArea'
import { usePromptStore } from 'pages/Prompt/store'

import styles from './PromptEntry.module.scss'

const PromptEntry = ({ showPromptInput }) => {
  const { prompt, setPrompt, submitPrompt, getPromptsHistory } =
    usePromptStore()
  const headers = useAuthHeaders()
  const navigate = useNavigate()
  const textareaRef = useRef(null)

  useAutoResizeTextArea(textareaRef?.current, prompt)

  const handleSubmitPrompt = async () => {
    const body = {
      prompt,
    }
    await submitPrompt({ body, navigate, headers })
    getPromptsHistory({ headers })
  }
  
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && prompt && !event.shiftKey) {
      event.preventDefault();
      handleSubmitPrompt(event);
    }
  };

  return showPromptInput ? (
    <div className={styles.promptEntryWrapper}>
      <textarea
        ref={textareaRef}
        className={styles.input}
        placeholder="What do you need designs for?"
        // onResize={handleResizeTextarea}
        onChange={(e) => setPrompt(e.target.value)}
        value={prompt}
        rows={1}
        onKeyPress={handleKeyPress}
      />
      <button
        disabled={!prompt}
        onClick={handleSubmitPrompt}
        className={styles.buttonWrapper}
      >
        <Image height={20} width={20} src={icons.sendIcon} alt="" />
      </button>
    </div>
  ) : null
}

export default PromptEntry
