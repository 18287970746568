import PropTypes, { any, number } from 'prop-types'
import ScrollableBanner from 'components/Banners/Banner1/ScrollableBanner'
import DefaultBanner from 'components/Banners/Banner1/DefaultBanner'

const Banner1 = ({ props }) => {
  const { sectionContent, cardContent } = props

  const isScrollableBanner =
    Array.isArray(cardContent) || Object.keys(cardContent)?.length !== 1
  const cardContentArray = Object.values(cardContent)
    ?.filter((item) => typeof item === 'object')
    ?.map((item) => item)

  return isScrollableBanner ? (
    <ScrollableBanner
      sectionContent={sectionContent}
      //pass cardContent here once issue
      cardContentArray={cardContentArray}
    />
  ) : (
    <DefaultBanner cardContent={cardContent} />
  )
}

Banner1.propTypes = {
  props: PropTypes.shape({
    sectionContent: PropTypes.shape({
      nCards: number,
      nImgagesPerCard: number,
      carousel: Boolean,
      orientation: 'landscape',
    }),
    cardContent: any,
  }),
}

export default Banner1
