import classNames from 'classnames'
import React from 'react'
import styles from './FlexView.module.scss'

const FlexView = ({
  inherit,
  flexDirection = 'column',
  gutter = 0,
  gap = 0,
  className,
  backgroundColor,
  borderRadius = 0,
  children,
  alignItems,
  justifyContent,
  alignSelf,
  width,
  height,
  zIndex,
  offset,
  ...rest
}) => {
  const flexViewStyles = {
    flexDirection,
    ...(gap || gutter ? { padding: `${gap}px ${gutter}px` } : {}),
    ...(backgroundColor ? { backgroundColor } : {}),
    ...(borderRadius ? { borderRadius } : {}),
    ...(alignItems ? { alignItems } : {}),
    ...(justifyContent ? { justifyContent } : {}),
    ...(alignSelf ? { alignSelf } : {}),
    ...(width ? { width } : {}),
    ...(height ? { height } : {}),
    ...(zIndex ? { zIndex } : {}),
    ...(offset ? { margin: `0 ${offset}px 0 ${offset}px` } : {}),
  }

  const flexViewWrapperClass = classNames(styles.flexViewBase, {
    [className || '']: true,
    [styles.inherit]: inherit,
  })

  return (
    <div className={flexViewWrapperClass} style={flexViewStyles} {...rest}>
      {children}
    </div>
  )
}

export default FlexView
