import React from 'react'
import styles from './CardSlider3.module.scss'

const CardSlider3 = ({ props }) => {
  const { cardsContent, rows, styleName, visibleCards } = props

  // const styleJson = StyleService.getStyleJsonFromName(styleName)
  // const sectionStyle = StyleService.getStylesFromProps(styleJson, 'Section')
  // const slidersettings = StyleService.getStylesFromProps(
  //   styleJson,
  //   'sliderSettings'
  // )

  // const settings = {
  //   dots: true, // Show navigation dots
  //   infinite: false, // Enable infinite looping
  //   slidesToShow: 1, // Number of cards to show per slide
  //   slidesToScroll: 1, // Number of cards to scroll per slide
  //   rows: slidersettings.rows,
  //   slidesPerRow: slidersettings.columns,
  // }

  return (
    <div className={styles.sliderWrapper}>
      {/* {sectiondata && (
        <div>
          {sectiondata.title && (
            <h3 className="TH3" style={{ textIndent: 'center' }}>
              {sectiondata.title}
            </h3>
          )}

          {sectiondata.subtitle && (
            <h3 className="TR1">{sectiondata.subtitle}</h3>
          )}

          {sectiondata.text && <p className="TR1">{sectiondata.text}</p>}
        </div>
      )} */}
      {cardsContent?.map((item, index) => (
        <div className={styles.sliderItem}>
          <img src={item?.image} alt="" className={styles.image} />
          <div className={styles.title}>{item?.title}</div>
        </div>
      ))}
    </div>
  )
}

export default CardSlider3
