import React from 'react'
import styles from './CardSlider.module.scss'

const CardSliderNetflix = ({ cardsContent, rows, visibleCards }) => {
  return (
    <div className={styles.netflixCardsWrapper}>
      {cardsContent?.map((item) => {
        return (
          <div className={styles.netflixItemWrapper}>
            <img src={item?.image} width={90} height={120} alt={item?.title} />
            <div className={styles.title}>{item?.title}</div>
          </div>
        )
      })}
    </div>
  )
}

export default CardSliderNetflix
