import React from 'react';
import { Card,Container,Row,Col } from 'react-bootstrap';
import  StyleService from '../../services/StyleService';
import {UilStar} from '@iconscout/react-unicons';

function Card2 (props){
const carddata = props.cardsContent;
const sectionStyle= StyleService.getStylesFromProps(props,'Section');
const cardStyle = StyleService.getStylesFromProps(props,'Card');
const imgStyle= StyleService.getStylesFromProps(props,'Image');
const sectiondata = props.sectionContent;


  return (

    <div style={{sectionStyle}} >
      {sectiondata && (

      <div>

        {sectiondata.title && (
          <h2 className="TH2" >
            {sectiondata.title}
          </h2>
        )}

        {sectiondata.subtitle && (
          <h3 className="TH3" >
            {sectiondata.subtitle}
          </h3>
        )}

        {sectiondata.text && (
          <p className="TR2" >
            {sectiondata.text}
          </p>
        )}
      </div>
    )} 
      {carddata.map((carditem, index) => (
        <Card  key={index} style={{cardStyle}}>     
        <div>
          <Container>
            <Row>
              <Col>                
                {carditem.image && <Card.Img  src={carditem.image}  variant={carditem.variant} style={imgStyle} alt={carditem.title} />} 
              
                <Card.Body >
                  <Card.Title className="TH2" >{carditem.title}</Card.Title>
                    <Card.Subtitle className="TH4">
                        <UilStar size={20} color="blue" />  
                        {carditem.subtitle}
                    </Card.Subtitle>
                  <Card.Text className="TR2" >{carditem.description}</Card.Text>                  
                </Card.Body>
              </Col>
            </Row>
          </Container>    
          </div> 
        </Card>
      )
      )}    
    </div>
  );  
  
  

 };

export default Card2;