import routes from 'lib/config/routes'
import { useHomeStore } from 'pages/Home/store'
import React, { useMemo } from 'react'
import { Navigate } from 'react-router-dom'

const PrivateRoute = ({ children }) => {
  const { isLoggedIn, accessToken } = useHomeStore()

  const isUserLoggedIn = useMemo(() => {
    return isLoggedIn && accessToken
  }, [isLoggedIn, accessToken])

  return isUserLoggedIn ? children : <Navigate to={routes.HOME} />
}

export default PrivateRoute
