import { useGoogleLogin } from '@react-oauth/google'
import { useHomeStore } from 'pages/Home/store'
import { useNavigate } from 'react-router-dom'
import useAdminHeaders from 'common/hooks/useAdminHeaders'

const useAuthActions = () => {
  const navigate = useNavigate()
  const headers = useAdminHeaders()
  const { getUserToken, getUserInfo } = useHomeStore()
      
      const login = useGoogleLogin({
            onSuccess: async (tokenResponse) => {
                  const userInfo = await getUserInfo(tokenResponse?.access_token)
                  const params = {
                        email: userInfo?.email,
                  }
                  getUserToken({ params, navigate, headers })
            },
            ux_mode: 'popup',
      })

   return {
         login
   }
}

export default useAuthActions