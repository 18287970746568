import React from 'react'
import styles from './CardSlider.module.scss'

const CardSliderAirbnb = ({ cardsContent, rows, visibleCards }) => {
  const totalRows = Math.ceil(cardsContent.length / visibleCards)

  const containerStyles = {
    display: 'grid',
    gridTemplateColumns: `repeat(${visibleCards}, 1fr)`,
    gridTemplateRows: `repeat(${totalRows}, auto)`,
    gap: '10px',
  }

  return (
    <div style={containerStyles} className={styles.x4Wrapper}>
      {cardsContent?.map((item, index) => (
        <div className={styles.sliderItem}>
          <img src={item?.image} alt="" className={styles.image} />
          <div className={styles.title}>{item?.title}</div>
        </div>
      ))}
    </div>
  )
}

export default CardSliderAirbnb
