import stylesData from '../styles/CategoryStyles.json'

class StyleService {
  static getStyleJsonFromName(styleName) {
    console.log('styelss', styleName)

    if (styleName) {
      try {
        if (stylesData.hasOwnProperty(styleName)) {
          return stylesData[styleName]
        } else {
          console.warn(
            `StyleName '${styleName}' not found in CategoryStyles.json`
          )
          return null
        }
      } catch (error) {
        console.error('Error reading CategoryStyles.json:', error)
        return null
      }
    }
  }

  static getStylesFromProps(props, styleLevel) {
    // Define your styles based on the props
    const styles = {}
    let styleProps

    if (props) {
      if (styleLevel === 'Card') {
        styleProps = props.cardStyles
      } else if (styleLevel === 'Image') {
        styleProps = props.imageStyles
      } else if (styleLevel === 'Section') {
        styleProps = props.sectionStyles
      } else if (styleLevel === 'TH1') {
        styleProps = props.tH1Styles
      } else if (styleLevel === 'TH2') {
        styleProps = props.tH2Styles
      } else if (styleLevel === 'TR1') {
        styleProps = props.tR1Styles
      } else if (styleLevel === 'TR2') {
        styleProps = props.tR2Styles
      } else if (styleLevel === 'sliderSettings') {
        styleProps = props.sliderSettings

        return styleProps
      }
      if (styleProps) {
        if (styleProps.backgroundColor) {
          styles.backgroundColor = styleProps.backgroundColor
        }

        if (styleProps.color) {
          styles.color = styleProps.color
        }

        if (styleProps.itemSpacing) {
          styles.itemSpacing = styleProps.itemSpacing
        }

        if (styleProps.gap) {
          styles.gap = styleProps.gap
        }

        if (styleProps.fontSize) {
          styles.fontSize = styleProps.fontSize
        }

        if (styleProps.width) {
          styles.width = styleProps.width
        }

        if (styleProps.height) {
          styles.height = styleProps.height
        }
        if (styleProps.margin) {
          styles.margin = styleProps.margin
        }

        if (styleProps.borderRadius) {
          styles.borderRadius = styleProps.borderRadius
        }

        if (styleProps.padding) {
          styles.padding = styleProps.padding
        }

        if (styleProps.border) {
          styles.border = styleProps.border
        }

        if (styleProps.borderWidth) {
          styles.borderWidth = styleProps.borderWidth
        }

        if (styleProps.borderStyle) {
          styles.borderStyle = styleProps.borderStyle
        }

        if (styleProps.borderColor) {
          styles.borderColor = styleProps.borderColor
        }

        if (styleProps.overflow) {
          styles.overflow = styleProps.overflow
        }

        if (styleProps.whiteSpace) {
          styles.whiteSpace = styleProps.whiteSpace
        }

        if (styleProps.textOverflow) {
          styles.textOverflow = styleProps.textOverflow
        }

        if (styleProps.objectFit) {
          styles.objectFit = styleProps.objectFit
        }

        if (styleProps.alignItems) {
          styles.alignItems = styleProps.alignItems
        }

        if (styleProps.justifyContent) {
          styles.justifyContent = styleProps.justifyContent
        }

        if (styleProps.textIndent) {
          styles.textIndent = styleProps.textIndent
        }

        if (styleProps.backgroundImage) {
          styles.backgroundImage = styleProps.backgroundImage
        }
      }
    }

    return styles
  }
}

export default StyleService
