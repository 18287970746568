import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap'
import StyleService from '../../services/StyleService'

const CardList1 = (props) => {
  const cardStyle = StyleService.getStylesFromProps(props, 'Card')
  const imgStyle = StyleService.getStylesFromProps(props, 'Image')
  const sectionStyle = StyleService.getStylesFromProps(props, 'Section')
  const slidersettings = props.sliderSettings
  const cardsliderdata = props.cardsContent
  const sectiondata = props.sectionContent

  const [visibleCards, setVisibleCards] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [cardsPerPage] = useState(5)

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight
      ) {
        loadMoreCards()
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    setVisibleCards(cardsliderdata.slice(0, cardsPerPage))
  }, [cardsliderdata, cardsPerPage])

  const loadMoreCards = () => {
    const startIndex = currentPage * cardsPerPage
    const endIndex = (currentPage + 1) * cardsPerPage
    const newVisibleCards = [
      ...visibleCards,
      ...cardsliderdata.slice(startIndex, endIndex),
    ]

    setVisibleCards(newVisibleCards)
    setCurrentPage((prevPage) => prevPage + 1)
  }

  return (
    <div>
      {visibleCards.map((item) => (
        <Card key={item.id}>
          <Card.Body>
            <Card.Title>{item.title}</Card.Title>
            <Card.Text>{item.description}</Card.Text>
          </Card.Body>
        </Card>
      ))}
    </div>
  )
}
export default CardList1
