import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {  Card,Button } from 'react-bootstrap';
import StyleService from '../../services/StyleService';

const Category2 = ( props ) => {  

  const styleName = props.styleName;

  const styleJson = StyleService.getStyleJsonFromName(styleName);
  const cardStyle = StyleService.getStylesFromProps(styleJson,'Card');
  const imgStyle= StyleService.getStylesFromProps(styleJson,'Image');
  const sectionStyle= StyleService.getStylesFromProps(styleJson,'Section');
  
  const slidersettings =  StyleService.getStylesFromProps(styleJson,'sliderSettings')

  // const slidersettings = props.sliderSettings;
  
  const cardsliderdata = props.cardsContent;
  const sectiondata = props.sectionContent;
  
  const settings = {
    dots: true, // Show navigation dots
    infinite: false, // Enable infinite looping
    slidesToShow: slidersettings.slideToShow, // Number of cards to show per slide
    slidesToScroll: slidersettings.slideToScroll,// Number of cards to scroll per slide   
    rows:slidersettings.rows,
    slidesPerRow: slidersettings.columns
    
  };

  return (

    <div style={sectionStyle}>
      {sectiondata && (
        <div>
          {sectiondata.title && (
              <h3 className="TH3" style={{"textIndent":"center"}}>
                {sectiondata.title}
              </h3>
          )}
          {sectiondata.subtitle && (
              <h3 className="TR1">
                {sectiondata.subtitle}
              </h3>
          )}
          {sectiondata.text && (
              <p className="TR1">
                {sectiondata.text}
              </p>
          )}
        </div>
      )} 
      <Slider {...settings}>
        {cardsliderdata.map((card) => (
          <div key={card.id}  style={{"textIndent":"center"}}>
            {card.image &&  (<Card style={cardStyle} className="slide-item">
                {card.image && (   <Card.Img variant="top" src={card.image} style={imgStyle} />)}
              </Card>)}       
              
            { (card.title ||card.subtitle|| card.description || card.buttonLink ) &&
              (
                <div style={{"textAlign":"center", "margin":"0px"}}>   
                {card.title && (  <h3 className="TH3" >{card.title}</h3>)}
                {card.subtitle && (  <h4 className="TR1"  >{card.subtitle}</h4>)}
                {card.description && ( <p className="TR2"  >{card.description}</p>)}
                {card.buttonLink && card.buttonLabel && (
                                <Button href={card.buttonLink} style={{"backgroundColor":"var(--CSecondary1)"}} >{card.buttonLabel}</Button>)
                }
                </div>               
              )}  
                         
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Category2;
