import React from 'react'

import { FlexView } from 'common/UI/FlexView'
import { Image } from 'common/UI/Image'
import { Separator } from 'common/UI/Separator'
import { Text } from 'common/UI/Text'
import homeBgImage from 'common/assets/images/files/home-bg-image.png'
import createcomponents from 'createcomponents'

import { CookingDesignsLoader } from '../CookingDesignsLoader'
import { Feedback } from '../Feedback'
import useLogic from './UIGenerator.logic'
import styles from './UIGenerator.module.scss'

const UIGenerator = () => {
  const { uiGeneratorData, showLoader, styleConfig } = useLogic()

  const empty = showLoader ? (
    <FlexView>
      {/* <Text textAlign="center" variant="p2">
        {prompt}
      </Text>
      <Text textAlign="center" variant="p2" color="var(--color-white-40)">
        {createdAt}
      </Text> */}
      <Separator height={76} />
      <CookingDesignsLoader />
    </FlexView>
  ) : (
    <FlexView inherit justifyContent="flex-end">
      <Text textAlign="center" variant="p1">
        Try it out
      </Text>
      <Separator height={76} />
      <Image src={homeBgImage} width={900} />
      <Separator height={32} />
    </FlexView>
  )

  return (
    <>
      {uiGeneratorData?.length > 0 ? (
        <>
          {/* <FlexView alignItems="center" justifyContent="center">
            <Text textAlign="center" variant="p2">
              {prompt}
            </Text>
            <Text textAlign="center" variant="p2" color="var(--color-white-40)">
              {createdAt}
            </Text>
          </FlexView> */}
          <div className={styles.uiAndFeedbackWrapper}>
            <div className={styles.backgroundWrapper}>
              <div
                className={styles.uiGeneratorWrapper}
                style={{
                  padding: styleConfig?.global_styles?.spacing,
                  fontFamily: styleConfig?.suggested_font?.['header_font'],
                  backgroundColor: styleConfig?.color_info?.background?.[2],
                  color: styleConfig?.color_info?.foreground?.[2],
                }}
              >
                {uiGeneratorData.map((block) => createcomponents(block))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <FlexView inherit justifyContent="center">
          {empty}
        </FlexView>
      )}
    </>
  )
}

export default UIGenerator
