import React from 'react'
import { Form, FormControl, Container, Row, Col } from 'react-bootstrap'
import { UilSearch, UilMicrophone } from '@iconscout/react-unicons'
import styles from './Search3.module.scss'
import StyleService from 'services/StyleService'

const Search3 = ({ props }) => {
  const { searchText, showSearch, showMic } = props
  const sectionStyle = StyleService.getStylesFromProps(props, 'Section')

  return (
    <Container className={styles.container}>
      <Col xs={1} md={1}>
        <Form
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '5px',
            color: 'var(--CPrimary1)',
          }}
        >
          {showSearch ? (
            <div style={{ padding: '0px 5px', marginRight: '20px' }}>
              <UilSearch size={20} />
            </div>
          ) : null}
        </Form>
      </Col>
      <Col xs={10} md={10}>
        <Form>
          <FormControl
            type="text"
            placeholder={searchText}
            className="flex-grow custom-form-control"
          />
        </Form>
      </Col>
      {showMic && (
        <Col xs={1} md={1}>
          <Form
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '5px',
              color: 'var(--CPrimary1)',
            }}
          >
            <div style={{ padding: '0px 10px', marginLeft: '20px' }}>
              <UilMicrophone size={20} />
            </div>
          </Form>
        </Col>
      )}
    </Container>
  )
}

export default Search3
