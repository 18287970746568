import React from 'react'
import Card1 from './components/Cards/Card1'
import Card2 from './components/Cards/Card2'
import CardSlider from './components/Slider/CardSlider'
import Header2 from './components/Headers/Header2'
import CardSlider2 from './components/Slider/CardSlider2'
import CardSlider3 from './components/Slider/CardSlider3'
import CardSlider4 from './components/Slider/CardSlider4'
import Search1 from './components/Search/Search1'
import CardList1 from './components/Lists (Deprecated)/CardList1'
import Header5 from './components/Headers/Header5'
import Header6 from './components/Headers/Header6'
import Search3 from './components/Search/Search3'
import Search4 from './components/Search/Search4'
import Search2 from './components/Search/Search2'
import Header4 from './components/Headers/Header4'
import Header3 from './components/Headers/Header3'
// import Header from './components/Headers/Header'
import Category1 from './components/Categories/Category1'
import Category2 from './components/Categories/Category2'
import Banner1 from './components/Banners/Banner1'
import Banner3 from './components/Banners/Banner3/Banner3'
import Banner2 from 'components/Banners/Banner2'

const Components = {
  card1: Card1,
  cardgroup1: Card1,
  cardgroup3: Card1,
  cardslider: CardSlider, //x4 done, netflix, airbnb left
  header2: Header2,
  header3: Header3,
  header4: Header4,
  header5: Header5,
  header6: Header6,
  cardslider2: CardSlider2,
  cardslider3: CardSlider3,
  search1: Search1,
  search3: Search3,
  cardlist1: CardList1,
  card2: Card2,
  search4: Search4,
  search2: Search2,
  category1: Category1,
  category2: Category2,
  banner1: Banner1, //done, just check banner1 booking.com style.
  banner3: Banner3,
  banner2: Banner1,
  cardslider4: CardSlider4,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (block) => {
  let item = block.component
  item = item.toLowerCase()
  if (typeof Components[item] !== 'undefined') {
    //  console.log("item type", typeof(item))
    console.log('====block', block, block.props)
    let element = React.createElement(Components[item], {
      key: block._uid,
      props: block.props,
    })
    let elementWithSeperationLine = <>{element}</>
    return elementWithSeperationLine
  }
  return React.createElement(
    () => <div>The component {block.component} has not been created yet.</div>,
    { key: block._uid }
  )
}
