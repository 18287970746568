import axios from 'axios'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import config from 'lib/config'
import routes from 'lib/config/routes'

import { initialState, processDetailsConverter } from './helpers'

const usePromptStore = create(
  devtools(
    (set, get) => ({
      ...initialState,
      setFeedback: async ({ key, value }) => {
        set({
          feedback: {
            ...get().feedback,
            [key]: value,
          },
        })
      },
      resetPrompts: () =>
        set({
          uiGeneratorData: null,
          prompt: '',
          feedback: {
            value: '',
            text: '',
          },
          showFeedbackSuccess: false,
        }),
      setPrompt: (prompt) => set({ prompt }),
      submitPrompt: async ({ body, navigate, headers }) => {
        try {
          set({ isLoading: true })
          const resp = await axios.post(`${config.BASE_URL}/process`, body, {
            headers,
          })

          if (resp?.data && resp?.data?.status !== 'error') {
            navigate(routes.PROMPT.replace(':id?', resp?.data?.process_id), {
              replace: true,
            })
            set({
              prompt: '',
            })
          }
        } catch (error) {
          return
        }
      },
      getProcessDetails: async ({ processId, headers }) => {
        try {
          set({
            uiGeneratorLoading: true,
            processData: null,
            uiGeneratorData: null,
          })
          const resp = await axios.get(
            `${config.BASE_URL}/status/${processId}`,
            {
              headers,
            }
          )

          if (resp?.data?.data && resp?.data?.data?.error !== 'error') {
            set({ processData: resp?.data?.data })
            const processData = resp?.data?.data?.Output
              ? JSON.parse(resp?.data?.data?.Output)
              : ''
            const { convertedData, styleConfig } =
              await processDetailsConverter(processData)
            set({ uiGeneratorData: convertedData, styleConfig })
          }
        } catch (error) {
          return
        } finally {
          set({ uiGeneratorLoading: false, isLoading: false })
        }
      },
      getPromptsHistory: async ({ headers }) => {
        try {
          set({ promptsHistoryLoading: true })
          const params = {}
          const resp = await axios.get(`${config.BASE_URL}/user-processes`, {
            params,
            headers,
          })
          if (resp?.data && resp?.data?.error !== 'error') {
            set({
              promptsHistory: resp?.data?.processes?.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              ),
            })
          }
        } catch (error) {
          return
        } finally {
          set({ promptsHistoryLoading: false })
        }
      },
      submitFeedback: async ({ headers, body }) => {
        try {
          set({ feedbackSubmitLoader: true })
          const resp = await axios.post(`${config.BASE_URL}/feedback`, body, {
            headers,
          })
          if (resp?.data && resp?.data?.status !== 'error') {
            set({
              showFeedbackSuccess: true,
            })
            setTimeout(() => {
              set({
                showFeedbackSuccess: false,
                feedback: {
                  ...initialState.feedback,
                },
              })
            }, 4000)
          }
        } catch (error) {
          return
        } finally {
          set({ feedbackSubmitLoader: false })
        }
      },
      resetPromptData: () => {
        set({
          processData: {},
        })
      },
    }),
    {
      name: 'INTERACXION_PROMPT_STORE', // name of the item in the storage (must be unique)
    }
  )
)

export { usePromptStore }
