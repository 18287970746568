import { googleLogout } from '@react-oauth/google'
import axios from 'axios'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import config from 'lib/config'
import routes from 'lib/config/routes'

const useHomeStore = create()(
  devtools(
    persist(
      (set, get) => ({
        isLoading: false,
        isLoggedIn: false,
        userInfo: null,
        accessToken: null,
        activeFAQ: 0,
        email: '',
        totalPagesCreated: 0,
        setEmail: (email) => set({
          email,
        }),
        setActiveFAQ: (index) =>
          set({
            activeFAQ: index,
          }),
        getUserToken: async ({ params = {}, navigate, headers }) => {
          try {
            set({ isLoading: true })
            const resp = await axios.get(`${config.BASE_URL}/get-user-token`, {
              params,
              headers,
            })

            if (resp?.data) {
              if (
                resp?.data?.status === 'error' &&
                resp?.data?.message === 'User not found'
              ) {
                get()?.createNewUser({ body: { ...params }, headers, navigate })
              } else if (
                resp?.data?.status !== 'error' &&
                resp?.data?.authToken
              ) {
                try {
                  set({
                    isLoggedIn: true,
                    accessToken: resp?.data?.authToken ?? null,
                  })
                  navigate(routes.PROMPT.replace('/:id', ''))
                } catch (error) {
                  return
                }
              }
            }
          } catch (error) {
            return
          } finally {
            set({ isLoading: false })
          }
        },
        createNewUser: async ({ body = {}, headers = {}, navigate }) => {
          try {
            set({ isLoading: true })
            const resp = await axios.post(
              `${config.BASE_URL}/create-user`,
              { ...body },
              {
                headers,
              }
            )
            if (resp?.data && !resp?.data?.error) {
              set({
                isLoggedIn: true,
                accessToken: resp.data?.authToken ?? null,
              })
              navigate(routes.PROMPT)
            }
          } finally {
            set({ isLoading: false })
          }
        },
        getUserInfo: async (accessToken) => {
          try {
            const response = await axios.get(
              'https://www.googleapis.com/oauth2/v1/userinfo',
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            )
            set({
              userInfo: response.data,
            })
            return response.data
          } catch (error) {
            return null
          }
        },
        logout: () => {
          try {
            googleLogout()
            set({
              userInfo: null,
              isLoggedIn: false,
              accessToken: null,
            })
          } catch (error) {
            return
          }
        },
        addSubscriber: async ({ headers }) => {
          try {
            const resp = await axios.post(
              `${config.BASE_URL}/add_subscriber`,
              { user_email: get().email?.trim() },
              {
                headers,
              }
            )
            if (resp?.data && !resp?.data?.error) {
              set({
                email: '',
              })
            }
          } finally {
            
          }
        },
        getInteracxionSummary: async ({ headers }) => {
          try {
            const resp = await axios.get(`${config.BASE_URL}/summary-dump`, {
              headers,
            })

            if (resp?.data && !resp?.data?.error) {
              const total = resp?.data?.find(({ metric }) => metric === "completedJobs")?.count
              set({
                totalPagesCreated: total
              })
            }
          } catch (error) {
            return
          } finally {
            set({ isLoading: false })
          }
        },
      }),
      {
        name: 'INTERACXION_HOME_STORE',
      }
    )
  )
)

export { useHomeStore }
